import React, { useState, useEffect, useCallback, useRef } from "react";
import "./App.css";
import CategorySelect from "./components/CategorySelect";
import RangeInput from "./components/RangeInput";
import QuestionDisplay from "./components/QuestionDisplay";
import ScoreDisplay from "./components/ScoreDisplay";
import GoogleAds from "./components/GoogleAds";
import Timer from "./components/Timer";

// Static function map moved outside the component
const categories = {
  tables: generateTableQuestion,
  squares: generateSquareQuestion,
  cubes: generateCubeQuestion,
  alphabet: generateAlphabetQuestion,
};

// Function definitions for question generation
function generateTableQuestion(minValue, maxValue) {
  const num = Math.floor(Math.random() * (maxValue - minValue + 1)) + minValue;
  const mult = Math.floor(Math.random() * 10) + 1;
  return {
    question: `${num} x ${mult}`,
    correctAnswer: num * mult,
  };
}

function generateSquareQuestion(minValue, maxValue) {
  const num = Math.floor(Math.random() * (maxValue - minValue + 1)) + minValue;
  return {
    question: `Square of ${num}`,
    correctAnswer: num * num,
  };
}

function generateCubeQuestion(minValue, maxValue) {
  const num = Math.floor(Math.random() * (maxValue - minValue + 1)) + minValue;
  return {
    question: `Cube of ${num}`,
    correctAnswer: num * num * num,
  };
}

function generateAlphabetQuestion() {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const char = alphabet[Math.floor(Math.random() * 26)];
  return {
    question: `Numerical value of '${char}'`,
    correctAnswer: char.charCodeAt(0) - 64,
  };
}

const App = () => {
  const [category, setCategory] = useState("tables");
  const [minValue, setMinValue] = useState(1);
  const [maxValue, setMaxValue] = useState(25);
  const [question, setQuestion] = useState("");
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [answer, setAnswer] = useState("");
  const [result, setResult] = useState("");
  const [correctCount, setCorrectCount] = useState(0);
  const [incorrectCount, setIncorrectCount] = useState(0);
  const [isOvertime, setIsOvertime] = useState(false);

  const inputRef = useRef(null);

  // UseCallback for generating new question
  const generateNewQuestion = useCallback(() => {
    const { question, correctAnswer } = categories[category](minValue, maxValue);
    setQuestion(question);
    setCorrectAnswer(correctAnswer);
    setIsOvertime(false); // Reset overtime message
  }, [category, minValue, maxValue]);

  useEffect(() => {
    generateNewQuestion();
  }, [category, minValue, maxValue, generateNewQuestion]);

  function handleSubmit() {
    if (parseInt(answer) === correctAnswer) {
      setResult("Correct!");
      setCorrectCount(correctCount + 1);
    } else {
      setResult(`Wrong! The correct answer is ${correctAnswer}`);
      setIncorrectCount(incorrectCount + 1);
    }
    setTimeout(() => {
      setAnswer("");
      setResult("");
      generateNewQuestion();
      if (inputRef.current) {
        inputRef.current.focus(); // Keep keyboard open on mobile
      }
    }, 2000);
  }

  return (
    <div className="app">
      <h1>SPEEDRUN SSC</h1>
      <CategorySelect
        category={category}
        setCategory={setCategory}
        setMaxValue={setMaxValue}
      />
      {category !== "alphabet" && (
        <RangeInput
          minValue={minValue}
          maxValue={maxValue}
          setMinValue={setMinValue}
          setMaxValue={setMaxValue}
        />
      )}
      <Timer
        key={question} // Reset timer when a new question is generated
        onTimeout={() => setIsOvertime(true)}
      />
      {isOvertime && <p className="overtime-message">Overtime!</p>}
      <QuestionDisplay
        question={question}
        answer={answer}
        setAnswer={setAnswer}
        result={result}
        handleSubmit={handleSubmit}
        inputRef={inputRef} // Pass the ref to keep the keyboard open
      />
      <ScoreDisplay correctCount={correctCount} incorrectCount={incorrectCount} />

      {/* Render Google Ads at the bottom */}
      <GoogleAds />
    </div>
  );
};

export default App;
