import React from "react";

const CategorySelect = ({ category, setCategory, setMaxValue }) => {
  return (
    <div className="select-container">
      <select
        value={category}
        onChange={(e) => {
          setCategory(e.target.value);
          if (e.target.value === "tables") setMaxValue(25);
          if (e.target.value === "squares") setMaxValue(35);
          if (e.target.value === "cubes") setMaxValue(15);
        }}
      >
        <option value="tables">Tables (up to 25)</option>
        <option value="squares">Squares (up to 35)</option>
        <option value="cubes">Cubes (up to 15)</option>
        <option value="alphabet">Alphabet Numerical Value</option>
      </select>
    </div>
  );
};

export default CategorySelect;
