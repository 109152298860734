import React from "react";

const RangeInput = ({ minValue, maxValue, setMinValue, setMaxValue }) => {
  return (
    <div className="range-container">
      <label>Min Value:</label>
      <input
        type="number"
        value={minValue}
        onChange={(e) => setMinValue(parseInt(e.target.value))}
      />
      <label>Max Value:</label>
      <input
        type="number"
        value={maxValue}
        onChange={(e) => setMaxValue(parseInt(e.target.value))}
      />
    </div>
  );
};

export default RangeInput;
