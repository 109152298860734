import React from "react";

const ScoreDisplay = ({ correctCount, incorrectCount }) => {
  return (
    <div className="score">
      Correct Answers: {correctCount} | Incorrect Answers: {incorrectCount}
    </div>
  );
};

export default ScoreDisplay;
