import React, { useEffect, useState } from "react";

const Timer = ({ onTimeout }) => {
  const [seconds, setSeconds] = useState(10);

  useEffect(() => {
    if (seconds > 0) {
      const countdown = setTimeout(() => setSeconds(seconds - 1), 1000);
      return () => clearTimeout(countdown);
    } else {
      onTimeout();
    }
  }, [seconds, onTimeout]);

  return <p className="timer">Time left: {seconds}s</p>;
};

export default Timer;
