import React from "react";

const QuestionDisplay = ({ question, answer, setAnswer, result, handleSubmit, inputRef }) => {
  return (
    <div className="question-container">
      <p>{question}</p>
      <input
        ref={inputRef} // Focus the input after each question
        type="number" // Display numeric keyboard on mobile
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter") handleSubmit(); // Submit on Enter key
        }}
      />
      <button onClick={handleSubmit}>Submit</button>
      <p>{result}</p>
    </div>
  );
};

export default QuestionDisplay;
