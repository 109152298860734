// components/GoogleAds.js
import React, { useEffect } from "react";

const GoogleAds = () => {
  useEffect(() => {
    // Check if adsbygoogle is available before pushing ads
    try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (e) {
        console.error("AdSense error", e);
      }
  }, []);

  return (
    <div className="ads-container">
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4274713400051192"
            crossorigin="anonymous"></script>
        <ins class="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-4274713400051192"
            data-ad-slot="2285003780"
            data-ad-format="auto"
            data-full-width-responsive="true">
            </ins>
    </div>
  );
};

export default GoogleAds;
